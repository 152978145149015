// extracted by mini-css-extract-plugin
export var author = "index-module--author--drmWc";
export var category = "index-module--category--8WMZp";
export var excerpt = "index-module--excerpt--9Ibma";
export var largeContainer = "index-module--largeContainer--cMZ-d";
export var largeThumbnail = "index-module--largeThumbnail--QpwIn";
export var largeTitle = "index-module--largeTitle--M7RWb";
export var smallContainer = "index-module--smallContainer--Bdp5J";
export var smallTextBox = "index-module--smallTextBox--oR5HO";
export var smallThumbnail = "index-module--smallThumbnail--0kRnS";
export var smallTitle = "index-module--smallTitle--MfKLS";